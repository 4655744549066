const appConfig = {
    apiPrefix: 'https://eventqiu.jagoan.info/api',
    authenticatedEntryPath: '/admin-eventqiu/dashboard',
    unAuthenticatedEntryPath: '/admin-eventqiu/sign-in',
    adminPath: '/admin-eventqiu',
    tourPath: '/',
    notFound: '/not-found',
    locale: 'en',
    enableMock: false,
}

export default appConfig
